import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import Seo from "../components/seo";

class PostTemplate extends Component {
  render() {
    const post = this.props.data.wpPost;

    return (
      <Layout>
        <Seo title={post.seo.title} description={post.seo.metaDesc} />

        <div className="wp-content">
          <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8 text-grey-darker">
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          </div>
          <div className="max-w-xl mx-auto">
            {/* <img
              className="w-full p-4 h-auto"
              src={post.featuredImage.node.localFile.publicURL}
              alt={post.featuredImage.title}
            /> */}
          </div>
          <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8 text-grey-darker">
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </div>
          <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8">
            <p className="my-8">
              <Link to="/blog/">Back to blog</Link>
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
};

export default PostTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      # featuredImage {
      #   title
      #   localFile {
      #     publicURL
      #   }
      # }
      seo {
        title
        metaDesc
        canonical
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
